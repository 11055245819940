
import guidedTourConstants from '~/store/guidedTour/-constants'
import { WebRequest } from '~/helpers/api'

export default {
  name: 'OrganismGuidedTourPresentationDrawer',
  props: {},
  data() {
    return {
      loadingBlocks: false,
      defaultOpen: false,
      presentationData: null,
      contentBlocks: []
    }
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    project() {
      return this.$store.state.project.projects.find(
        (project) => project.slug === this.activeProject
      )
    },
    building() {
      return this.$store.state.base?.landlordDetails?.buildings?.find(
        (b) => b.id === this.space.building_id
      )
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    cdnBase() {
      return this.$store.getters['base/cdnBase']
    },
    isOpenDrawer() {
      return this.$refs.drawer?.isOpen
    }
  },
  watch: {
    presentationData: function (newValue, oldValue) {
      if (newValue) {
        this.fetchContentBlocks()
      } else {
        this.contentBlocks = []
      }
    }
  },
  mounted() {
    this.subscribe()
  },
  methods: {
    subscribe() {
      this.$store.subscribe((mutation, state) => {
        if (
          mutation.type ===
          guidedTourConstants.withNamespace(
            guidedTourConstants.mutation.MUTATE_ON_CLEAR_PRESENTATION
          )
        ) {
          this.presentationData = null
          this.closeDrawer()
        }
        if (
          mutation.type ===
          guidedTourConstants.withNamespace(
            guidedTourConstants.mutation.MUTATE_ON_SHOW_PRESENTATION
          )
        ) {
          this.presentationData = mutation.payload
        }
      })
    },
    closeDrawer() {
      this.$refs.drawer?.close()
    },
    openDrawer() {
      this.$refs.drawer?.open()
    },
    fetchContentBlocks() {
      this.loadingBlocks = true
      WebRequest.GET(this.$webApi.guidedTour.locationContentBlocks(this.presentationData.id))
        .then((res) => {
          if (res.response.status === 200) {
            this.contentBlocks = res.data.result
            if (this.contentBlocks.length) {
              this.openDrawer()
            }
          } else {
            this.contentBlocks = []
          }
          this.loadingBlocks = false
        })
        .catch((e) => {
          console.error(e)
          this.contentBlocks = []
          this.loadingBlocks = false
        })
    }
  }
}
