
import ClickOutside from 'vue-click-outside'
import baseConstants from '~/store/base/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from '~/store/modal/-constants'

export default {
  name: 'OrganismCartSpacesBuilding',
  directives: {
    ClickOutside
  },
  props: {},
  data() {
    return {
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    }
  },
  computed: {
    isOpen() {
      return this.$store.state.requestOffer.cartSpacesIsOpen
    },
    buildingStore() {
      return this.$store.state.building
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    totalPeople() {
      if (this.unitOfMeasure === 1) {
        const unitSQM = this.totalSpace / 10.763910417
        return Math.floor(unitSQM / 8)
      }
      return Math.floor(this.totalSpace / 8)
    },
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen
    },
    hasSpaceSelected() {
      return !!this.buildingStore.space.spaceData
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    selectedSpace() {
      const space = { ...this.buildingStore.space.spaceData }
      const building = this.buildings.find((b) => b.id === space.building_id)
      const floor = this.floors.find((f) => f.id === space.floor_id)
      space.building = building
      space.floor = floor
      return space
    },
    showMobileSelectedSpace() {
      return window.innerWidth <= 1200 && this.hasSpaceSelected
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people'];
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    getSpacePeople(sqm) {
      if (this.unitOfMeasure === 1) {
        const unitSQM = sqm / 10.763910417
        return Math.floor(unitSQM / 8)
      }
      return Math.floor(sqm / 8)
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    toggleOpenState() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_cart_list',
          payload: {
            opened: !this.isOpen
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.CHANGE_CART_SPACES_STATE),
        !this.isOpen
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      )
    },
    handleClickOutside() {
      // const requestOffer = this.$store.getters.constants.requestOffer;
      // this.$store.dispatch(requestOffer.withNamespace(requestOffer.action.CHANGE_CART_SPACES_STATE), false);
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'building',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
    },
    addSpaceToCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'building',
            space
          }
        })
      }
      this.$store.getters.this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        space
      )
    }
  }
}
