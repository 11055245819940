
import { FloorViewMode } from '@bright-spaces/engine-3d/dist/definitions'
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'OrganismGuidedTourLauncher',
  props: {},
  data() {
    return {
      isMenuOpen: false,
      autoContinueProgressInterval: null,
      autoContinueProgress: 0,
      progressStep: 1000
    }
  },
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    availableTours() {
      return this.$store.state.guidedTour.availableTours || []
    },
    hasMultipleGuidedTours() {
      return this.availableTours.length > 1
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    guidedTourAutoContinueDelay() {
      return this.$store.state.guidedTour.autoContinueDelay
    },
    isPlaying() {
      return this.$store.state.guidedTour.isPlaying
    }
  },
  watch: {
    guidedTourAutoContinueDelay: function (nextValue) {
      if (nextValue) {
        this.handleAutoContinueTimer()
      } else {
        this.resetAutoContinueData()
      }
    }
  },
  beforeDestroy() {
    this.resetAutoContinueData()
  },
  methods: {
    handleAutoContinueTimer() {
      this.autoContinueProgressInterval = setInterval(() => {
        const stepIncrease =
          ((this.autoContinueProgress + this.progressStep) * 100) / this.guidedTourAutoContinueDelay
        this.autoContinueProgress =
          this.autoContinueProgress >= 100 ? 0 : this.autoContinueProgress + stepIncrease
      }, this.progressStep)
    },
    resetAutoContinueData() {
      clearInterval(this.autoContinueProgressInterval)
      this.autoContinueProgressInterval = null
      this.autoContinueProgress = 0
    },
    handleLaunch() {
      if (!this.hasMultipleGuidedTours) {
        this.enableGuidedTourMode()
      } else {
        this.isMenuOpen = !this.isMenuOpen
      }
    },
    async handleSpecificLaunch(uuid) {
      this.$store.dispatch(
        guidedTourConstants.withNamespace(guidedTourConstants.action.SET_GUIDED_TOUR_IDENTIFIER),
        uuid
      )
      await this.$store.dispatch(
        guidedTourConstants.withNamespace(guidedTourConstants.action.GET_GUIDED_TOUR_DETAILS)
      )
      this.enableGuidedTourMode()
    },
    enableGuidedTourMode() {
      const manager = this.engine3d.getClientManager()
      manager.showLevelElements(this.spaceViewMode)
      manager.switchCamera(FloorViewMode.FIRST_PERSON)
      setTimeout(() => {
        manager.resize()
      }, 501)
      this.$store.dispatch(guidedTourConstants.withNamespace('enableGuidedTourMode'))
    },
    disableGuidedTourMode() {
      const manager = this.engine3d.getClientManager()
      manager.switchCamera(FloorViewMode.DEFAULT)
      setTimeout(() => {
        manager.resize()
      }, 501)
      this.$store.dispatch(guidedTourConstants.withNamespace('disableGuidedTourMode'))
    },
    startTour() {
      this.$store.dispatch(guidedTourConstants.withNamespace('startTour'))
    },
    pauseTour() {
      this.$store.dispatch(guidedTourConstants.withNamespace('pauseTour'))
    },
    nextLocation() {
      this.$store.dispatch(guidedTourConstants.withNamespace('nextLocation'))
    },
    prevLocation() {
      this.$store.dispatch(guidedTourConstants.withNamespace('prevLocation'))
    },
    resetTour() {
      this.$store.dispatch(guidedTourConstants.withNamespace('resetTour'))
    },
    clearAutoContinueTimeout() {
      this.$store.dispatch(guidedTourConstants.withNamespace('clearAutoContinueTimeout'))
    }
  }
}
