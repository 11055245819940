
import guidedTourConstants from '~/store/guidedTour/-constants'

export default {
  name: 'OrganismGuidedTourTimeline',
  computed: {
    locale() {
      return this.$i18n?.locale || 'en'
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    timelineLocations() {
      return this.$store.getters['guidedTour/timelineLocations']
    }
  },
  methods: {
    async handleTimeLineNavigation(location) {
      await this.$store.dispatch(guidedTourConstants.withNamespace('resetTourForCustomNavigation'))
      setTimeout(() => {
        this.$store.dispatch(guidedTourConstants.withNamespace('handleLocationFade'), location)
      }, 500)
    }
  }
}
